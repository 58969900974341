import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import menu from "images/menu.jpeg";
import menup2 from "images/menu-p2.jpeg";


export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-orange-900 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Delicious & Affordable <HighlightedText>Vegan & Vegetarian Meals Near You.</HighlightedText></>}
        description="Lentils is a family operated small business, bringing the finest Indian Pakistani catering to you! We are Minority-owned business."
        imageSrc={menu}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Call us"
        primaryButtonUrl="tel:+14074628808"
        watchVideoButtonText="Meet The Chef"
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>menu.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            About <HighlightedText>us</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Healthy food",
            description: "For thousands of years India has been known for its vegetarian history and biggest vegetarian population, we now bring this all together to offer this healthy food to our community. "
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Vegan and Vegetarian",
            description: "At Lentils we are commited to providing our customers with \
            plant-based vegan and vegetarian food. We offer Indian style \
            cuisine for the American taste. Our food is high quality and \
            affordable. Family owned and operated."
          },
          {
            imageSrc: celebrationIconImageSrc,
            description: "Enjoy this healthy food prepared in a unique way"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Caterer</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Recommendations",
            value: "1500+"
          }
        ]}
        primaryButtonText="Call us"
        primaryButtonUrl="tel:+14074628808"
        imageInsideDiv={false}
        imageSrc={menup2}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}

